<!-- 数据统计-->
<template>
  <div id="productionReport" v-loading="loading" ref="main" :element-loading-text="$t('alarm.a0')">
    <!-- 页面标题 -->
    <div class="productionReport-title">
      <!-- 生产能耗报表 -->
      <span>{{ $t("energy.enedata534") }}</span>
    </div>
    <a-row style="height: calc(100% - 30px);">
        <a-col :span="6" style="height: 100%;padding-bottom:5px;">
          <template>
              <div style="" class="production-tree-box">
                  <!-- <a-input-search style="margin-bottom: 8px" placeholder="查询" @change="queryPoints" /> -->
                  <!-- <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
                    {{ $t("energy.enedata025") }}
                  </a-checkbox> -->
                  <a-tree
                    style=""
                    class="production-tree"
                    show-line
                    :tree-data="treeProductionList"
                    :replaceFields="{children:'children', title:'name', key:'key' }"
                    checkable
                    v-model="checkedPoints"
                    :expanded-keys="extPointNoLists"
                    :auto-expand-parent="autoExpandParent"
                    @select="pointSelect"
                    @check="pointCheck"
                    @expand="expandChange"
                    :multiple="true"
                  >
                  </a-tree>
              </div>
          </template>
        </a-col>
        <a-col :span="18" style="padding: 0 2px 5px;height: 100%;">
             <!-- 查询条件1 -->
             <a-row>
               <a-col :span="24">

            <div class="display-between inquire">
              <div class="display-between-2 inquire-item">
                 <div class="display-column">
                    <span>{{ $t("energy.enedata075") }}</span>
                    <a-radio-group v-model="treeLevel" button-style="solid" @change="treeLevelChanges">
                        <a-radio-button value="1">{{ $t("energy.enedata076") }}</a-radio-button>
                        <a-radio-button value="2">{{ $t("energy.enedata077") }}</a-radio-button>
                        <a-radio-button value="3">{{ $t("energy.enedata078") }}</a-radio-button>
                    </a-radio-group>
                </div>
                  <!-- 类型  @change="rptChange"-->
                <!-- <div class="display-column">
                  <span>{{ $t("energy.enedata002") }}</span>
                  <a-radio-group v-model="rpt" button-style="solid">
                    <a-radio-button  v-for="(itme, index) in this.rptKinds"
                        :key="index"
                        :value="itme.no"> {{ itme.text }}</a-radio-button>
                  </a-radio-group>
                </div> -->
                
                <!-- 显示对象 @change="drawMyChart"-->
                <!-- <div class="display-column">
                  <span>{{ $t("energy.enedata004") }}</span>
                  <a-radio-group v-model="chartKind" button-style="solid" >
                    <a-radio-button value="line">{{ $t("energy.enedata013") }}</a-radio-button>
                    <a-radio-button value="bar">{{ $t("energy.enedata014") }}</a-radio-button>
                  </a-radio-group>
                </div>
                <div class="display-column">
                    <span>{{ $t("energy.enedata271") }}:</span>
                    <a-radio-group v-model="carbon" button-style="solid" >
                        <a-radio-button value="0">{{ $t("energy.enedata009") }}</a-radio-button>
                        <a-radio-button value="1">{{ $t("energy.enedata443") }}</a-radio-button>
                    </a-radio-group>
                </div> -->
                <div class="display-column">
                    <span>{{$t('energy.enedata511')}}</span>
                    <a-range-picker
                      v-model="date"
                      :ranges="ranges"
                      :format="dateFormat"
                      @change="onChange"
                    />
                </div>
               
              </div>
               
              <a-button type="primary" @click="getRptData">{{ $t("energy.enedata006") }}</a-button>
              <!-- 查询 -->
            </div>
               </a-col>
             </a-row>
             <a-row>
               <a-col>
                <div class="display-between-2 inquire">
           
                  <!-- 类型  @change="rptChange"-->
                  <span>{{ $t("energy.enedata533") }}：</span>
                  <div style="margin-left:10px;">
                    <a-checkbox-group v-model="groups">
                    <a-checkbox :value="itme.no"  :key="index"  v-for="(itme, index) in this.groupbys">
                      {{ itme.text }}
                    </a-checkbox>
                    </a-checkbox-group>
                  </div>
                  
                </div>
               </a-col>
             </a-row>
            <div style="height: calc(100% - 150px);background:#7682CE;margin-bottom:5px;">
                <template> 
                  <a-carousel arrows>
                    <!-- <div slot="prevArrow"  class="custom-slick-arrow" style="left: 10px;zIndex: 1;">
                      <a-icon type="left-circle" />
                    </div>
                    <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
                      <a-icon type="right-circle" />
                    </div> -->

                    <!-- <div style="height:100%;">
                      <div class="chart-box">
                        <div id="myChart" :style="myChartSize" ></div>
                      </div>

                    </div> -->
                    <div style="height:100%;">
                        <div class="table-box">
                          <a-table
                              :columns="columns"
                              :data-source="tableData"
                              :pagination="pagination"
                              :scroll="Yscroll"
                              size="middle"
                            />
                        </div>
                    </div>
                  </a-carousel>
                </template>
            </div>
            <div style="text-align: right;">
                <a-button type="primary" style="width: 120px;margin-right:5px;" @click="csvout">
                  {{$t('energy.enedata042')}}
                </a-button>
            </div>
        </a-col>
         
    </a-row>
   


  </div>
</template>

<script>
import SubMenu from "../components/measureGroup/eneMeasureGroupSubMenu.vue";
import { getProductionConsumptionReport,downloadEnergyCSVDataWithURL  } from "../../../api/energy";
import { downloadUrl } from "../../../utils/utils";
import moment from 'moment';
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
import { EleResize } from "../../../utils/esresize";

export default {
  data() {
    let cc = this.$t('energy.enedata544');
    let cm = this.$t('energy.enedata545');
    let lm = this.$t('energy.enedata546');
    return {
      loading:false,
      rpt: "3",
      chartKind: "line",
      rptKinds:[],

      productionLineTypeList:[],
      treeProductionList:[],
      treePointNoLists:[],
      extPointNoLists:[],
      autoExpandParent:true,

      groupbys:[],
      groups:[],
      ranges:{ 
            '今天': [moment(), moment()]
          , '当月': [moment().startOf('month'), moment()]
          , '上月': [moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')] 
      },
      // ranges:{ 
      //       cc : [moment(), moment()]
      //     , cm : [moment().startOf('month'), moment()]
      //     , lm : [moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')] 
      // },
      dateFormat: 'YYYY/MM/DD',
      date:[moment().startOf('month'), moment()],

      dataLists:[],
      chartData:null,
      measurePoint:null,
      difPoint:false,
      difYear:false,
      msel:false,
     
      checkedPoints:[],
      measurePointIds:[],

      selectedKeys:[],
      checkedKeys:[],
      



      myChartSize:{width: '100%', height: '0px'},
      mode:"multiple",
      indeterminate:false,
      checkAll:false,
      treeLevel:"3",
      myChart:undefined,
      carbon:"0",

      pagination:false,
      Yscroll: { x: 1100,y:240 },
      tableData:[],
      columns:[
            {title: 'NO'	           ,dataIndex: "key",              width: 60},
          
            {title: this.$t('consume.a15'), dataIndex: 'clientName',width:200      },
            {title: this.$t('energy.enedata254'), dataIndex: 'siteGrName',width:200      },
            {title: this.$t('consume.a14'), dataIndex: 'siteName',width:200      },
            {title: this.$t('energy.enedata484'), dataIndex: 'productionLineName',width:200    },
            {title: this.$t('energy.enedata489'), dataIndex: 'productionName',width:200     },
            {title: this.$t('energy.enedata490'), dataIndex: 'productionOrderNo',width:200      },
            {title: this.$t('energy.enedata511'), dataIndex: 'productionStartDate',width:150      },
            {title: this.$t('energy.enedata493'), dataIndex: 'productionNumber',width:200      },
            {title: this.$t('energy.enedata494'), dataIndex: 'productionUnit2',width:80      },
            {title: this.$t('energy.enedata495'), dataIndex: 'productionConsumption',width:200      },
            {title: this.$t('energy.enedata496'), dataIndex: 'productionUnit1',width:80      },
            {title: this.$t('energy.enedata497'), dataIndex: 'productionUnitConsumption',width:200      },
            {title: this.$t('energy.enedata498'), dataIndex: 'productionUnit',width:80      }
            
        ],
    };
  },
  activated(){
    // console.log("activated",this.$store.getters.clientId);
    // if(this.$store.getters.clientId){
    //     console.log("activated load data");
    //     this.initPage();
    // }
  },
  mounted() {
    this.initPage();
    //实现自适应部分
    this.resizeChart();
    window.addEventListener("resize", () => this.resizeChart());
    
  },
  methods: {
    
    onChange(dates, dateStrings) {
        console.log('From: ', dates[0], ', to: ', dates[1]);
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
    },
    expandChange(expandedKeys, expanded){
      console.log('expanded',expanded);
      this.extPointNoLists = expandedKeys;
      this.autoExpandParent = false;
    },
    treeLevelChanges(){
      this.initPage();
    },
    // onCheckAllChange(e){
    //   let bol = e && e.target.checked;
    //   Object.assign(this, {
    //     checkedPoints: bol ? this.treePointNoLists : [],
    //     indeterminate: false,
    //     checkAll: bol,
    //   });
    // },
    resizeChart(){
      if(this.$refs.main){
        let height = this.$refs.main.clientHeight;
        let tableHeight = height - 180;
        // console.log("resizeChart=",tableHeight);
        this.myChartSize.height = (height-160)+"px";
        this.Yscroll.y = tableHeight-20;
      }
    },
    initPage(){
      let params = {
        action:0,
        clientId: this.$store.getters.clientId,
        sitegrId: this.$store.getters.sitegrId,
        siteId: this.$route.query.id,
        tenantId: 0,
        level:this.treeLevel,
      };

      console.log(params);
      this.loading = true;
     
      this.checkedPoints = [];
      this.treePointNoLists = [];
      this.checkAll = false;
      this.indeterminate = false;
      this.selectedKeys = [];
      this.autoExpandParent = true;
      getProductionConsumptionReport(params)
      .then((res) => {
        console.log(res);
        this.loading = false;
        this.rptKinds = res.data.rptKinds;
        this.productionLineTypeList = res.data.productionLineTypeList;
        this.treeProductionList = [...this.productionLineTypeList];
      
        this.setTreePointNoLists(this.treeProductionList,'POINT');
        console.log('POINT',this.treePointNoLists);
        this.setTreePointNoLists(this.treeProductionList,'EXPAND');
        console.log('EXPAND',this.extPointNoLists);

        this.groupbys = res.data.groupbys;
        // this.drawDefultChart();        
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    setTreePointNoLists(treeProductionList,flag){
       if(treeProductionList && treeProductionList.length>0){
         for(let i=0;i<treeProductionList.length;i++){
              if('POINT'===flag){
                if(treeProductionList[i].level === 'pnt'){
                    this.treePointNoLists.push(treeProductionList[i].key);
                }else{
                    this.setTreePointNoLists(treeProductionList[i].children,flag);
                }
              }else{
                if(treeProductionList[i].level != 'pnt'){
                    this.extPointNoLists.push(treeProductionList[i].key);
                    this.setTreePointNoLists(treeProductionList[i].children,flag);
                }else{
                }
              }
          }
       }
    },
    getRptData(){
      let productionLineTypes = [];
      let siteIds = [];
      let siteGrIds = [];
      let clientIds = [];
      this.checkedKeys.forEach(element => {
          let vals = element.split('@@');
          if(vals[1]==='ProductionLineType'){
              productionLineTypes.push(vals[0]);
          }
      });

      this.selectedKeys.forEach(element => {
          let vals = element.split('@@');
          if(vals[1]==='sitegr'){
              siteGrIds.push(vals[0]);
          } else if(vals[1]==='site'){
              siteIds.push(vals[0]);
          } else if(vals[1]==='client'){
              clientIds.push(vals[0]);
          }
      });

      console.log('this.groups',this.groups);

      let params = {
          action:1,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
          groupbys: this.groups.join(','),
          reportType:this.rpt,
          productionLineTypes:productionLineTypes.join(','),
          productionStartDate:this.date[0].format('YYYY/MM/DD') + " 00:00:00",
          productionEndDate:this.date[1].format('YYYY/MM/DD') + " 23:59:59",
          siteIds:siteIds.join(','),
          siteGrIds:siteGrIds.join(','),
          clientIds:clientIds.join(','),
      };
      console.log(params);
      this.loading = true;
    
      getProductionConsumptionReport(params)
      .then((res) => {
          console.log("getProductionConsumptionReport result:",res);
         
          this.tableData = [];
          let key = 1;
          res.data.reportList.forEach(ele => {
              ele.key = key;
              key++;
              this.tableData .push(ele)
          });
          this.columns =  res.data.columns;
          this.loading = false;
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    csvout(){
       let productionLineTypes = [];
      let siteIds = [];
      let siteGrIds = [];
      let clientIds = [];
      this.checkedKeys.forEach(element => {
          let vals = element.split('@@');
          if(vals[1]==='ProductionLineType'){
              productionLineTypes.push(vals[0]);
          }
      });

      this.selectedKeys.forEach(element => {
          let vals = element.split('@@');
          if(vals[1]==='sitegr'){
              siteGrIds.push(vals[0]);
          } else if(vals[1]==='site'){
              siteIds.push(vals[0]);
          } else if(vals[1]==='client'){
              clientIds.push(vals[0]);
          }
      });

      console.log('this.groups',this.groups);

      let params = {
          action:1,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
          groupbys: this.groups.join(','),
          reportType:this.rpt,
          productionLineTypes:productionLineTypes.join(','),
          productionStartDate:this.date[0].format('YYYY/MM/DD') + " 00:00:00",
          productionEndDate:this.date[1].format('YYYY/MM/DD') + " 23:59:59",
          siteIds:siteIds.join(','),
          siteGrIds:siteGrIds.join(','),
          clientIds:clientIds.join(','),
      };
      console.log(params);
      this.loading = true;

      downloadEnergyCSVDataWithURL('bivale/energy/production/consumption/report/download',params)
      .then((res) => {
          let name = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.$t('energy.enedata534')+'-'+moment().format('YYYYMMDDHHmmssSSS')+'.zip';
          console.log("downloadEnergyCSVDataWithURL Result",name);
          downloadUrl(res,name);
      })
      .catch((err) => {
          this.loading = false;
          console.log(err);
      })
      .finally(()=>{
          this.loading = false;
      });
    },
    pointSelect(selectedKeys, info){
      this.selectedKeys = selectedKeys;
    },
    pointCheck(checkedKeys, info){
      this.checkedKeys = checkedKeys;
    },
    // subQueryPoints(point,value){
    //     if(point.children && point.children.length>0 ){
    //          let arr = new Array();
    //          for (let i = 0; i < point.children.length; i++) {
    //             let child = point.children[i];
    //             if(child.level === 'pnt'){
    //               if(new String(child.name).indexOf(value)>=0){
    //                 arr.push(child);
    //               }
    //             }else{
    //               this.subQueryPoints(child,value);
    //               if(child.children.length>0 ){
    //                 arr.push(child);
    //               }
    //             }
    //           }
    //           point.children = arr;
    //     }
    // },
    // queryPoints(e){
    //   this.checkedPoints = [];
    //   this.measurePointIds = [];
    //   this.checkAll = false;
    //   this.indeterminate = false;
    //   const value = e.target.value;
    //   console.log("queryPoints",value);
    //   console.log("points",this.pointLists);
    //   let treeProductionList = new Array();
    //   if(value===''){
    //       treeProductionList = this.pointLists;
    //   }else{
    //       let points = JSON.parse(JSON.stringify(this.pointLists));
    //       // points.push(...this.pointLists) ;
    //       for (let i = 0; i < points.length; i++) {
    //         let cc = points[i];
    //         this.subQueryPoints(cc,value);
    //         if(cc.children.length>0 ){
    //           treeProductionList.push( cc);
    //         }
    //       }
         
    //   }
    //   console.log("queryPoints treeProductionList",treeProductionList);

    //   this.treeProductionList = treeProductionList;
    //   this.setTreePointNoLists(this.treeProductionList,'EXPAND');
    //   console.log('queryPoints EXPAND',this.extPointNoLists);
    //   this.autoExpandParent = true;
    // },
  },
  
  destroyed(){
    window.removeEventListener("resize", () => this.resizeChart());
  },
  components: {
    'sub-menu': SubMenu,
  },
};
</script>

<style scoped>
.show{
 display: block;
 
}
.hide{
  display:none;  
}
#productionReport {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
}
.productionReport-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 10px;
  color: #3e3a39;
}
.productionReport-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background: #7585cd;
  margin-right: 20px;
}
.display-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.display-between-2 {
  display: flex;
  justify-content: flex-start;
}
.display-column {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.display-column span {
  font-size: 14px;
  margin-bottom: 5px;
}
.inquire {
  padding:0 10px;
  margin-bottom: 10px;
}
.inquire-item {
  width: 100%;
}
.chart-box{
  height: 100%;
  padding: 5px;
  margin: 10px 5px;
}


.table-box{
  height: 100%;
  padding: 5px;
  margin: 10px 5px;
  background: #fff;
} 

.production-tree-box{width: 100%;height: 100%;padding:10px;border: 1px solid #d9d9d9 ;}
.production-tree{width: 100%;height:100%;overflow: auto;}

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 100%;
  /* line-height: 160px; */
  background: #7682CE;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>